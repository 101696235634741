"use strict";
import MapMarkersController from "src/decidim/map/controller/markers";
import MapStaticController from "src/decidim/map/controller/static";
import MapDragMarkerController from "src/decidim/map/controller/drag_marker";
const createMapController = function(mapId, config) {
  if (config.type === "static") {
    return new MapStaticController(mapId, config);
  } else if (config.type === "drag-marker") {
    return new MapDragMarkerController(mapId, config);
  }
  return new MapMarkersController(mapId, config);
};
window.Decidim.createMapController = createMapController;
