"use strict";
;
(function(jQuery2, undefined) {
  var oldManip = jQuery2.fn.domManip, tmplItmAtt = "_tmplitem", htmlExpr = /^[^<]*(<[\w\W]+>)[^>]*$|\{\{\! /, newTmplItems = {}, wrappedItems = {}, appendToTmplItems, topTmplItem = { key: 0, data: {} }, itemKey = 0, cloneIndex = 0, stack = [];
  function newTmplItem(options, parentItem, fn, data) {
    var newItem = {
      data: data || (parentItem ? parentItem.data : {}),
      _wrap: parentItem ? parentItem._wrap : null,
      tmpl: null,
      parent: parentItem || null,
      nodes: [],
      calls: tiCalls,
      nest: tiNest,
      wrap: tiWrap,
      html: tiHtml,
      update: tiUpdate
    };
    if (options) {
      jQuery2.extend(newItem, options, { nodes: [], parent: parentItem });
    }
    if (fn) {
      newItem.tmpl = fn;
      newItem._ctnt = newItem._ctnt || newItem.tmpl(jQuery2, newItem);
      newItem.key = ++itemKey;
      (stack.length ? wrappedItems : newTmplItems)[itemKey] = newItem;
    }
    return newItem;
  }
  jQuery2.each({
    appendTo: "append",
    prependTo: "prepend",
    insertBefore: "before",
    insertAfter: "after",
    replaceAll: "replaceWith"
  }, function(name, original) {
    jQuery2.fn[name] = function(selector) {
      var ret = [], insert = jQuery2(selector), elems, i, l, tmplItems, parent = this.length === 1 && this[0].parentNode;
      appendToTmplItems = newTmplItems || {};
      if (parent && parent.nodeType === 11 && parent.childNodes.length === 1 && insert.length === 1) {
        insert[original](this[0]);
        ret = this;
      } else {
        for (i = 0, l = insert.length; i < l; i++) {
          cloneIndex = i;
          elems = (i > 0 ? this.clone(true) : this).get();
          jQuery2.fn[original].apply(jQuery2(insert[i]), elems);
          ret = ret.concat(elems);
        }
        cloneIndex = 0;
        ret = this.pushStack(ret, name, insert.selector);
      }
      tmplItems = appendToTmplItems;
      appendToTmplItems = null;
      jQuery2.tmpl.complete(tmplItems);
      return ret;
    };
  });
  jQuery2.fn.extend({
    // Use first wrapped element as template markup.
    // Return wrapped set of template items, obtained by rendering template against data.
    tmpl: function(data, options, parentItem) {
      return jQuery2.tmpl(this[0], data, options, parentItem);
    },
    // Find which rendered template item the first wrapped DOM element belongs to
    tmplItem: function() {
      return jQuery2.tmplItem(this[0]);
    },
    // Consider the first wrapped element as a template declaration, and get the compiled template or store it as a named template.
    template: function(name) {
      return jQuery2.template(name, this[0]);
    },
    domManip: function(args, table, callback, options) {
      if (args[0] && args[0].nodeType) {
        var dmArgs = jQuery2.makeArray(arguments), argsLength = args.length, i = 0, tmplItem;
        while (i < argsLength && !(tmplItem = jQuery2.data(args[i++], "tmplItem"))) {
        }
        if (argsLength > 1) {
          dmArgs[0] = [jQuery2.makeArray(args)];
        }
        if (tmplItem && cloneIndex) {
          dmArgs[2] = function(fragClone) {
            jQuery2.tmpl.afterManip(this, fragClone, callback);
          };
        }
        oldManip.apply(this, dmArgs);
      } else {
        oldManip.apply(this, arguments);
      }
      cloneIndex = 0;
      if (!appendToTmplItems) {
        jQuery2.tmpl.complete(newTmplItems);
      }
      return this;
    }
  });
  jQuery2.extend({
    // Return wrapped set of template items, obtained by rendering template against data.
    tmpl: function(tmpl, data, options, parentItem) {
      var ret, topLevel = !parentItem;
      if (topLevel) {
        parentItem = topTmplItem;
        tmpl = jQuery2.template[tmpl] || jQuery2.template(null, tmpl);
        wrappedItems = {};
      } else if (!tmpl) {
        tmpl = parentItem.tmpl;
        newTmplItems[parentItem.key] = parentItem;
        parentItem.nodes = [];
        if (parentItem.wrapped) {
          updateWrapped(parentItem, parentItem.wrapped);
        }
        return jQuery2(build(parentItem, null, parentItem.tmpl(jQuery2, parentItem)));
      }
      if (!tmpl) {
        return [];
      }
      if (typeof data === "function") {
        data = data.call(parentItem || {});
      }
      if (options && options.wrapped) {
        updateWrapped(options, options.wrapped);
      }
      ret = jQuery2.isArray(data) ? jQuery2.map(data, function(dataItem) {
        return dataItem ? newTmplItem(options, parentItem, tmpl, dataItem) : null;
      }) : [newTmplItem(options, parentItem, tmpl, data)];
      return topLevel ? jQuery2(build(parentItem, null, ret)) : ret;
    },
    // Return rendered template item for an element.
    tmplItem: function(elem) {
      var tmplItem;
      if (elem instanceof jQuery2) {
        elem = elem[0];
      }
      while (elem && elem.nodeType === 1 && !(tmplItem = jQuery2.data(elem, "tmplItem")) && (elem = elem.parentNode)) {
      }
      return tmplItem || topTmplItem;
    },
    // Set:
    // Use $.template( name, tmpl ) to cache a named template,
    // where tmpl is a template string, a script element or a jQuery instance wrapping a script element, etc.
    // Use $( "selector" ).template( name ) to provide access by name to a script block template declaration.
    // Get:
    // Use $.template( name ) to access a cached template.
    // Also $( selectorToScriptBlock ).template(), or $.template( null, templateString )
    // will return the compiled template, without adding a name reference.
    // If templateString includes at least one HTML tag, $.template( templateString ) is equivalent
    // to $.template( null, templateString )
    template: function(name, tmpl) {
      if (tmpl) {
        if (typeof tmpl === "string") {
          tmpl = buildTmplFn(tmpl);
        } else if (tmpl instanceof jQuery2) {
          tmpl = tmpl[0] || {};
        }
        if (tmpl.nodeType) {
          tmpl = jQuery2.data(tmpl, "tmpl") || jQuery2.data(tmpl, "tmpl", buildTmplFn(tmpl.innerHTML));
        }
        return typeof name === "string" ? jQuery2.template[name] = tmpl : tmpl;
      }
      return name ? typeof name !== "string" ? jQuery2.template(null, name) : jQuery2.template[name] || // If not in map, treat as a selector. (If integrated with core, use quickExpr.exec)
      jQuery2.template(null, htmlExpr.test(name) ? name : jQuery2(name)) : null;
    },
    encode: function(text) {
      return ("" + text).split("<").join("&lt;").split(">").join("&gt;").split('"').join("&#34;").split("'").join("&#39;");
    }
  });
  jQuery2.extend(jQuery2.tmpl, {
    tag: {
      "tmpl": {
        _default: { $2: "null" },
        open: "if($notnull_1){_=_.concat($item.nest($1,$2));}"
        // tmpl target parameter can be of type function, so use $1, not $1a (so not auto detection of functions)
        // This means that {{tmpl foo}} treats foo as a template (which IS a function).
        // Explicit parens can be used if foo is a function that returns a template: {{tmpl foo()}}.
      },
      "wrap": {
        _default: { $2: "null" },
        open: "$item.calls(_,$1,$2);_=[];",
        close: "call=$item.calls();_=call._.concat($item.wrap(call,_));"
      },
      "each": {
        _default: { $2: "$index, $value" },
        open: "if($notnull_1){$.each($1a,function($2){with(this){",
        close: "}});}"
      },
      "if": {
        open: "if(($notnull_1) && $1a){",
        close: "}"
      },
      "else": {
        _default: { $1: "true" },
        open: "}else if(($notnull_1) && $1a){"
      },
      "html": {
        // Unecoded expression evaluation.
        open: "if($notnull_1){_.push($1a);}"
      },
      "=": {
        // Encoded expression evaluation. Abbreviated form is ${}.
        _default: { $1: "$data" },
        open: "if($notnull_1){_.push($.encode($1a));}"
      },
      "!": {
        // Comment tag. Skipped by parser
        open: ""
      }
    },
    // This stub can be overridden, e.g. in jquery.tmplPlus for providing rendered events
    complete: function(items) {
      newTmplItems = {};
    },
    // Call this from code which overrides domManip, or equivalent
    // Manage cloning/storing template items etc.
    afterManip: function afterManip(elem, fragClone, callback) {
      var content = fragClone.nodeType === 11 ? jQuery2.makeArray(fragClone.childNodes) : fragClone.nodeType === 1 ? [fragClone] : [];
      callback.call(elem, fragClone);
      storeTmplItems(content);
      cloneIndex++;
    }
  });
  function build(tmplItem, nested, content) {
    var frag, ret = content ? jQuery2.map(content, function(item) {
      return typeof item === "string" ? (
        // Insert template item annotations, to be converted to jQuery.data( "tmplItem" ) when elems are inserted into DOM.
        tmplItem.key ? item.replace(/(<\w+)(?=[\s>])(?![^>]*_tmplitem)([^>]*)/g, "$1 " + tmplItmAtt + '="' + tmplItem.key + '" $2') : item
      ) : (
        // This is a child template item. Build nested template.
        build(item, tmplItem, item._ctnt)
      );
    }) : (
      // If content is not defined, insert tmplItem directly. Not a template item. May be a string, or a string array, e.g. from {{html $item.html()}}.
      tmplItem
    );
    if (nested) {
      return ret;
    }
    ret = ret.join("");
    ret.replace(/^\s*([^<\s][^<]*)?(<[\w\W]+>)([^>]*[^>\s])?\s*$/, function(all, before, middle, after) {
      frag = jQuery2(middle).get();
      storeTmplItems(frag);
      if (before) {
        frag = unencode(before).concat(frag);
      }
      if (after) {
        frag = frag.concat(unencode(after));
      }
    });
    return frag ? frag : unencode(ret);
  }
  function unencode(text) {
    var el = document.createElement("div");
    el.innerHTML = text;
    return jQuery2.makeArray(el.childNodes);
  }
  function buildTmplFn(markup) {
    return new Function(
      "jQuery",
      "$item",
      "var $=jQuery,call,_=[],$data=$item.data;with($data){_.push('" + // Convert the template into pure JavaScript
      jQuery2.trim(markup).replace(/([\\'])/g, "\\$1").replace(/[\r\t\n]/g, " ").replace(/\$\{([^\}]*)\}/g, "{{= $1}}").replace(
        /\{\{(\/?)(\w+|.)(?:\(((?:[^\}]|\}(?!\}))*?)?\))?(?:\s+(.*?)?)?(\(((?:[^\}]|\}(?!\}))*?)\))?\s*\}\}/g,
        function(all, slash, type, fnargs, target, parens, args) {
          var tag = jQuery2.tmpl.tag[type], def, expr, exprAutoFnDetect;
          if (!tag) {
            throw "Template command not found: " + type;
          }
          def = tag._default || [];
          if (parens && !/\w$/.test(target)) {
            target += parens;
            parens = "";
          }
          if (target) {
            target = unescape(target);
            args = args ? "," + unescape(args) + ")" : parens ? ")" : "";
            expr = parens ? target.indexOf(".") > -1 ? target + parens : "(" + target + ").call($item" + args : target;
            exprAutoFnDetect = parens ? expr : "(typeof(" + target + ")==='function'?(" + target + ").call($item):(" + target + "))";
          } else {
            exprAutoFnDetect = expr = def.$1 || "null";
          }
          fnargs = unescape(fnargs);
          return "');" + tag[slash ? "close" : "open"].split("$notnull_1").join(target ? "typeof(" + target + ")!=='undefined' && (" + target + ")!=null" : "true").split("$1a").join(exprAutoFnDetect).split("$1").join(expr).split("$2").join(
            fnargs ? fnargs.replace(/\s*([^\(]+)\s*(\((.*?)\))?/g, function(all2, name, parens2, params) {
              params = params ? "," + params + ")" : parens2 ? ")" : "";
              return params ? "(" + name + ").call($item" + params : all2;
            }) : def.$2 || ""
          ) + "_.push('";
        }
      ) + "');}return _;"
    );
  }
  function updateWrapped(options, wrapped) {
    options._wrap = build(
      options,
      true,
      // Suport imperative scenario in which options.wrapped can be set to a selector or an HTML string.
      jQuery2.isArray(wrapped) ? wrapped : [htmlExpr.test(wrapped) ? wrapped : jQuery2(wrapped).html()]
    ).join("");
  }
  function unescape(args) {
    return args ? args.replace(/\\'/g, "'").replace(/\\\\/g, "\\") : null;
  }
  function outerHtml(elem) {
    var div = document.createElement("div");
    div.appendChild(elem.cloneNode(true));
    return div.innerHTML;
  }
  function storeTmplItems(content) {
    var keySuffix = "_" + cloneIndex, elem, elems, newClonedItems = {}, i, l, m;
    for (i = 0, l = content.length; i < l; i++) {
      if ((elem = content[i]).nodeType !== 1) {
        continue;
      }
      elems = elem.getElementsByTagName("*");
      for (m = elems.length - 1; m >= 0; m--) {
        processItemKey(elems[m]);
      }
      processItemKey(elem);
    }
    function processItemKey(el) {
      var pntKey, pntNode = el, pntItem, tmplItem, key;
      if (key = el.getAttribute(tmplItmAtt)) {
        while (pntNode.parentNode && (pntNode = pntNode.parentNode).nodeType === 1 && !(pntKey = pntNode.getAttribute(tmplItmAtt))) {
        }
        if (pntKey !== key) {
          pntNode = pntNode.parentNode ? pntNode.nodeType === 11 ? 0 : pntNode.getAttribute(tmplItmAtt) || 0 : 0;
          if (!(tmplItem = newTmplItems[key])) {
            tmplItem = wrappedItems[key];
            tmplItem = newTmplItem(tmplItem, newTmplItems[pntNode] || wrappedItems[pntNode], null, true);
            tmplItem.key = ++itemKey;
            newTmplItems[itemKey] = tmplItem;
          }
          if (cloneIndex) {
            cloneTmplItem(key);
          }
        }
        el.removeAttribute(tmplItmAtt);
      } else if (cloneIndex && (tmplItem = jQuery2.data(el, "tmplItem"))) {
        cloneTmplItem(tmplItem.key);
        newTmplItems[tmplItem.key] = tmplItem;
        pntNode = jQuery2.data(el.parentNode, "tmplItem");
        pntNode = pntNode ? pntNode.key : 0;
      }
      if (tmplItem) {
        pntItem = tmplItem;
        while (pntItem && pntItem.key != pntNode) {
          pntItem.nodes.push(el);
          pntItem = pntItem.parent;
        }
        delete tmplItem._ctnt;
        delete tmplItem._wrap;
        jQuery2.data(el, "tmplItem", tmplItem);
      }
      function cloneTmplItem(key2) {
        key2 = key2 + keySuffix;
        tmplItem = newClonedItems[key2] = newClonedItems[key2] || newTmplItem(tmplItem, newTmplItems[tmplItem.parent.key + keySuffix] || tmplItem.parent, null, true);
      }
    }
  }
  function tiCalls(content, tmpl, data, options) {
    if (!content) {
      return stack.pop();
    }
    stack.push({ _: content, tmpl, item: this, data, options });
  }
  function tiNest(tmpl, data, options) {
    return jQuery2.tmpl(jQuery2.template(tmpl), data, options, this);
  }
  function tiWrap(call, wrapped) {
    var options = call.options || {};
    options.wrapped = wrapped;
    return jQuery2.tmpl(jQuery2.template(call.tmpl), call.data, options, call.item);
  }
  function tiHtml(filter, textOnly) {
    var wrapped = this._wrap;
    return jQuery2.map(
      jQuery2(jQuery2.isArray(wrapped) ? wrapped.join("") : wrapped).filter(filter || "*"),
      function(e) {
        return textOnly ? e.innerText || e.textContent : e.outerHTML || outerHtml(e);
      }
    );
  }
  function tiUpdate() {
    var coll = this.nodes;
    jQuery2.tmpl(null, null, null, this).insertBefore(coll[0]);
    jQuery2(coll).remove();
  }
})(jQuery);
